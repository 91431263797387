var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.subtask)?_c('div',{staticClass:"subtask",style:({ background: _vm.taskCategoryColor })},[_c('TaskStatus',{attrs:{"status":_vm.subtask.progress}}),(_vm.isEnrolledCounseling)?_c('router-link',{attrs:{"to":{
      name: 'counselingSubtaskView',
      params: {
        id: _vm.$route.params.id,
        subtaskId: _vm.subtask.id
      }
    }}},[_vm._v(" "+_vm._s(_vm.subtask.title)+" ")]):_c('router-link',{attrs:{"to":{
      name: 'counselingUserSubtaskView',
      params: {
        username: _vm.$route.params.username,
        subtaskId: _vm.subtask.id
      }
    }}},[_vm._v(" "+_vm._s(_vm.subtask.title)+" ")])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }