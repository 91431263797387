<template>
  <div
    v-if="subtask"
    class="subtask"
    :style="{ background: taskCategoryColor }"
  >
    <TaskStatus :status="subtask.progress" />
    <router-link
      v-if="isEnrolledCounseling"
      :to="{
        name: 'counselingSubtaskView',
        params: {
          id: $route.params.id,
          subtaskId: subtask.id
        }
      }"
    >
      {{ subtask.title }}
    </router-link>
    <router-link
      v-else
      :to="{
        name: 'counselingUserSubtaskView',
        params: {
          username: $route.params.username,
          subtaskId: subtask.id
        }
      }"
    >
      {{ subtask.title }}
    </router-link>
  </div>
</template>

<script>
import TaskStatus from "@/views/private/Counseling/Tasks/Components/TaskStatus";

export default {
  props: {
    subtask: {
      type: Object,
      default: null
    },
    taskCategoryColor: {
      type: String,
      default: "#ffffff"
    },
    isEnrolledCounseling: {
      type: Boolean,
      default: false
    }
  },
  components: {
    TaskStatus
  }
};
</script>
<style lang="scss" scoped>
.subtask {
  display: flex;
  font-size: 14px;
  color: #606266;
  text-align: left;
  padding: 4px;

  a {
    color: #606266 !important;
  }

  a:hover {
    text-decoration: none;
  }

  a::after {
    content: "";
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
  }
}
</style>
