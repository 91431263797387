var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.$i18n.locale},[_c('table',{ref:"spreadsheet"},[_c('thead',[_c('tr',[_c('td',[_vm._v("Category")]),_c('td',[_vm._v("Task")]),_vm._l((Object.keys(_vm.fullTasksPeriods)),function(year){return _vm._l((_vm.fullTasksPeriods[year].months),function(month){return _c('td',{key:`${year}_${month}`},[_vm._v(" "+_vm._s(month)+" ")])})})],2)]),_c('tbody',[_vm._l((_vm.tasks),function(task){return [_c('tr',{key:`${task.id}_${task.groupIndex}`},[(_vm.isFirstNewCategory(task.id, task.groupIndex))?[_c('td',{attrs:{"rowspan":_vm.getRepeatCategoryCount(task.taskCategory.id)}},[_c('div',{staticClass:"category",style:({ background: task.taskCategory.color })},[_vm._v(" "+_vm._s(task.taskCategory.title[_vm.$i18n.locale])+" ")])])]:_c('td'),(task.groupIndex === 0)?[_c('td',{attrs:{"rowspan":_vm.getRepeatTaskCount(task.id)}},[_c('div',{staticClass:"task",style:({ background: task.taskCategory.color })},[_vm._v(" "+_vm._s(task.title[_vm.$i18n.locale])+" ")])])]:_c('td'),_vm._l((Object.keys(_vm.fullTasksPeriods)),function(year){return [_vm._l((_vm.fullTasksPeriods[year].months),function(month){return [(_vm.getSubtask(task, year, month))?[(
                    _vm.isStartSubtaskMonth(
                      _vm.getSubtask(task, year, month),
                      year,
                      month
                    )
                  )?[_c('td',{key:`${year}_${month}`,attrs:{"colspan":_vm.getSubtaskMonthLength(_vm.getSubtask(task, year, month))}},[_c('Subtask',{attrs:{"subtask":_vm.getSubtask(task, year, month),"taskCategoryColor":task.taskCategory.color,"isEnrolledCounseling":_vm.isEnrolledCounseling}})],1)]:[_c('td',{key:`${year}_${month}`})]]:_c('td',{key:`${year}_${month}`})]})]})],2)]})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }