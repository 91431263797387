<template>
  <div>
    <div class="d-flex justify-content-center">
      <span class="action-icon-without-pointer">
        <span :key="status" v-if="status === this.UNFINISHED">
          <i class="far fa-stop-circle" />
        </span>
        <span :key="status" v-if="status === this.PROCESSING">
          <i class="far fa-play-circle" />
        </span>
        <span :key="status" v-if="status === this.FINISHED">
          <i class="fas fa-check" />
        </span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "TaskStatus",
  props: {
    status: {
      default: null,
      type: String
    }
  },
  computed: {
    UNFINISHED() {
      return "UNFINISHED";
    },
    PROCESSING() {
      return "PROCESSING";
    },
    FINISHED() {
      return "FINISHED";
    }
  }
};
</script>
