var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"containerBody"},[_c('Breadcrumb',[_c('template',{slot:"restPage"},[(_vm.isEnrolledCounseling)?_c('el-breadcrumb-item',[_c('router-link',{attrs:{"to":{
            name: 'counselingClassesDetail',
            params: { id: _vm.$route.params.id }
          }}},[_c('IsOnlineIcon',{attrs:{"isOnline":_vm.sessionClass.course_session.is_online}}),_vm._v(" "+_vm._s(_vm.sessionClass.title)+" ")],1)],1):_c('el-breadcrumb-item',[_c('router-link',{attrs:{"to":{ name: 'counseling' }}},[_vm._v(" Counseling ")])],1),_c('el-breadcrumb-item',[_vm._v(" "+_vm._s(_vm.pageTitle)+" ")])],1)],2),_c('div',[_c('h1',[_vm._v(" "+_vm._s(_vm.pageTitle)+" "),(_vm.isEnrolledCounseling)?_c('router-link',{attrs:{"to":{
          name: 'createCounselingSubtasks',
          params: { id: _vm.$route.params.id }
        }}},[_c('i',{staticClass:"fa fa-plus"})]):_c('router-link',{attrs:{"to":{
          name: 'createCounselingUserSubtasks',
          params: { username: _vm.$route.params.username }
        }}},[_c('i',{staticClass:"fa fa-plus"})])],1),_c('hr',{staticClass:"separate-line"}),_c('div',{staticClass:"tasksTable"},[(_vm.tasks.length > 0)?_c('TasksTable',{attrs:{"tasks":_vm.tasks,"isEnrolledCounseling":_vm.isEnrolledCounseling}}):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }