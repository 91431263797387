<template>
  <div class="containerBody">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item v-if="isEnrolledCounseling">
          <router-link
            :to="{
              name: 'counselingClassesDetail',
              params: { id: $route.params.id }
            }"
          >
            <IsOnlineIcon :isOnline="sessionClass.course_session.is_online" />
            {{ sessionClass.title }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item v-else>
          <router-link :to="{ name: 'counseling' }">
            Counseling
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ pageTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <div>
      <h1>
        {{ pageTitle }}
        <router-link
          v-if="isEnrolledCounseling"
          :to="{
            name: 'createCounselingSubtasks',
            params: { id: $route.params.id }
          }"
        >
          <i class="fa fa-plus" />
        </router-link>
        <router-link
          v-else
          :to="{
            name: 'createCounselingUserSubtasks',
            params: { username: $route.params.username }
          }"
        >
          <i class="fa fa-plus" />
        </router-link>
      </h1>
      <hr class="separate-line" />
      <div class="tasksTable">
        <TasksTable
          v-if="tasks.length > 0"
          :tasks="tasks"
          :isEnrolledCounseling="isEnrolledCounseling"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import Breadcrumb from "@/components/Breadcrumb";
import IsOnlineIcon from "@/components/icon/IsOnline";
import TasksTable from "@/views/private/Counseling/Tasks/TasksTable";
import tasksApi from "@/apis/tasks";
import privateApi from "@/apis/private";
import profileApi from "@/apis/profile";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  components: {
    Breadcrumb,
    IsOnlineIcon,
    TasksTable
  },
  data() {
    return {
      student: {},
      sessionClass: {
        course_session: {}
      },
      tasks: [],
      displayMonth: null
    };
  },
  computed: {
    pageTitle() {
      if (this.student.first_name) {
        return `${this.student.first_name}'s Tasks`;
      } else {
        return "";
      }
    },
    studentUserId() {
      return this.student.id;
    },
    isEnrolledCounseling() {
      return this.$route.params.id !== undefined;
    },
    earliestStartDate() {
      let earliestStartDate = "";
      this.tasks.forEach(({ subtasks }) => {
        subtasks.forEach(({ start_date }) => {
          if (
            earliestStartDate === "" ||
            moment(start_date).isBefore(moment(earliestStartDate))
          ) {
            earliestStartDate = moment(start_date);
          }
        });
      });

      return earliestStartDate;
    }
  },
  watch: {
    earliestStartDate() {
      this.displayMonth = this.earliestStartDate;
    }
  },
  async created() {
    await this.setLessonAndUser();
    this.setUserTasks(this.studentUserId);
  },
  methods: {
    async setLessonAndUser() {
      if (this.isEnrolledCounseling) {
        await this.fetchCustomLesson();
      } else {
        await this.fetchUserProfile();
      }
    },
    async fetchCustomLesson() {
      const customLesson = await privateApi.getCustomLesson({
        class_id: this.$route.params.id
      });
      this.student = customLesson.student_class_tickets[0].student;
      this.sessionClass = {
        ...customLesson.session_class
      };
    },
    async fetchUserProfile() {
      const {
        basic_info,
        student: { counseling_schools_type }
      } = await profileApi.getUserByName(this.$route.params.username);
      this.student = { ...basic_info, counseling_schools_type };
    },
    async setUserTasks() {
      this.subtasksCache = {};
      const getFirstHasNoRepeatIndex = (tasks, currentSubTask) => {
        let taskIndex = 0;
        for (const task of tasks) {
          const taskHasNoSubTaskRepeatPeriod = task.subtasks.every(subTask => {
            const {
              start_date: currentStart,
              due_date: currentEnd
            } = currentSubTask;
            const { start_date: start, due_date: end } = subTask;
            const startYearMonth = moment(
              moment(start)
                .startOf("month")
                .format("YYYY/MM/DD")
            );
            const endYearMonth = moment(
              moment(end)
                .endOf("month")
                .format("YYYY/MM/DD")
            );
            const currentStartAndEndDateIsBeforeTargetStartDate =
              moment(currentStart).isBefore(startYearMonth) &&
              moment(currentEnd).isBefore(startYearMonth);
            const currentStartAndEndDateIsAfterTargetEndDate =
              moment(currentStart).isAfter(endYearMonth) &&
              moment(currentEnd).isAfter(endYearMonth);
            return (
              currentStartAndEndDateIsBeforeTargetStartDate ||
              currentStartAndEndDateIsAfterTargetEndDate
            );
          });
          if (taskHasNoSubTaskRepeatPeriod) {
            return taskIndex;
          } else {
            taskIndex += 1;
          }
        }
        return taskIndex;
      };
      const { task_categories } = await tasksApi.fetchSubtasks(this.student.id);
      const subtasks = [];
      task_categories.forEach(taskCategory => {
        const taskCategoryWithoutTasks = {
          id: taskCategory.id,
          title: taskCategory.title,
          color: taskCategory.color
        };
        taskCategory.tasks.forEach(tasks => {
          const workTasks = {
            taskCategory: taskCategoryWithoutTasks,
            id: tasks.id,
            title: tasks.title,
            subtasks: []
          };
          let tasksByGroupSubtasksPeriod = [
            _.cloneDeep({ ...workTasks, groupIndex: 0 })
          ];
          tasks.sub_tasks.forEach(subTask => {
            const insertTasksIndex = getFirstHasNoRepeatIndex(
              tasksByGroupSubtasksPeriod,
              subTask
            );
            if (tasksByGroupSubtasksPeriod[insertTasksIndex] === undefined) {
              tasksByGroupSubtasksPeriod[insertTasksIndex] = _.cloneDeep({
                ...workTasks,
                groupIndex: insertTasksIndex
              });
            }
            tasksByGroupSubtasksPeriod[insertTasksIndex].subtasks.push(subTask);
          });
          subtasks.push(...tasksByGroupSubtasksPeriod);
        });
      });

      this.tasks = subtasks;
    }
  }
};
</script>

<style lang="scss" scoped>
.containerBody {
  margin: 0px 120px;
}

::v-deep .el-month-table td.today .cell {
  font-weight: bolder;
}

::v-deep .jexcel > tbody > tr > td.readonly {
  position: relative;
  padding: 0px;
}

.tasksTable {
  overflow: scroll;
}

@media screen and (max-width: 992px) {
  .containerBody {
    margin: 0px 20px;
  }
}
</style>
