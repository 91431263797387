<template>
  <div :key="$i18n.locale">
    <table ref="spreadsheet">
      <thead>
        <tr>
          <td>Category</td>
          <td>Task</td>
          <template v-for="year in Object.keys(fullTasksPeriods)">
            <td
              :key="`${year}_${month}`"
              v-for="month in fullTasksPeriods[year].months"
            >
              {{ month }}
            </td>
          </template>
        </tr>
      </thead>
      <tbody>
        <template v-for="task in tasks">
          <tr :key="`${task.id}_${task.groupIndex}`">
            <template v-if="isFirstNewCategory(task.id, task.groupIndex)">
              <td :rowspan="getRepeatCategoryCount(task.taskCategory.id)">
                <div
                  class="category"
                  :style="{ background: task.taskCategory.color }"
                >
                  {{ task.taskCategory.title[$i18n.locale] }}
                </div>
              </td>
            </template>
            <td v-else></td>
            <template v-if="task.groupIndex === 0">
              <td :rowspan="getRepeatTaskCount(task.id)">
                <div
                  class="task"
                  :style="{ background: task.taskCategory.color }"
                >
                  {{ task.title[$i18n.locale] }}
                </div>
              </td>
            </template>
            <td v-else></td>
            <template v-for="year in Object.keys(fullTasksPeriods)">
              <template v-for="month in fullTasksPeriods[year].months">
                <template v-if="getSubtask(task, year, month)">
                  <template
                    v-if="
                      isStartSubtaskMonth(
                        getSubtask(task, year, month),
                        year,
                        month
                      )
                    "
                  >
                    <td
                      :key="`${year}_${month}`"
                      :colspan="
                        getSubtaskMonthLength(getSubtask(task, year, month))
                      "
                    >
                      <Subtask
                        :subtask="getSubtask(task, year, month)"
                        :taskCategoryColor="task.taskCategory.color"
                        :isEnrolledCounseling="isEnrolledCounseling"
                      />
                    </td>
                  </template>
                  <template v-else>
                    <td :key="`${year}_${month}`" />
                  </template>
                </template>
                <td v-else :key="`${year}_${month}`"></td>
              </template>
            </template>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from "moment";
import jexcel from "jexcel";
import "jexcel/dist/jexcel.css";
import Subtask from "@/views/private/Counseling/Tasks/Subtask";

export default {
  props: {
    tasks: {
      type: Array,
      default: () => []
    },
    isEnrolledCounseling: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Subtask
  },
  data() {
    return {
      subtasksCache: {},
      spreadsheet: null
    };
  },
  computed: {
    earliestStartDate() {
      let earliestStartDate = "";
      this.tasks.forEach(({ subtasks }) => {
        subtasks.forEach(({ start_date }) => {
          if (
            earliestStartDate === "" ||
            moment(start_date).isBefore(moment(earliestStartDate))
          ) {
            earliestStartDate = moment(start_date);
          }
        });
      });

      return earliestStartDate;
    },
    latestDueDate() {
      let latestDueDate = "";
      this.tasks.forEach(({ subtasks }) => {
        subtasks.forEach(({ due_date }) => {
          if (
            latestDueDate === "" ||
            moment(due_date).isAfter(moment(latestDueDate))
          ) {
            latestDueDate = moment(due_date);
          }
        });
      });

      return latestDueDate;
    },
    fullTasksPeriods() {
      let earliestStartDate = this.earliestStartDate;
      let latestDueDate = this.latestDueDate;
      let periods = {};
      if (earliestStartDate && latestDueDate) {
        const workLatestDueDate = latestDueDate.format("YYYY/M/DD");
        latestDueDate = moment(workLatestDueDate).add(1, "M");
        while (!moment(earliestStartDate).isSame(latestDueDate, "month")) {
          const workEarliestStartDate = earliestStartDate.format("YYYY/M/DD");
          const [year, month] = workEarliestStartDate.split("/");

          periods[year] = {
            months: [...(periods[year]?.months || []), month]
          };

          earliestStartDate = moment(workEarliestStartDate).add(1, "M");
        }
      }
      return periods;
    },
    yearColSpanMerges() {
      return Object.keys(this.fullTasksPeriods).map(year => ({
        title: year,
        colspan: this.fullTasksPeriods[year].months.length
      }));
    },
    tasksForJexcel() {
      let tasksForJexcel = this.tasks.map(task => [
        task.taskCategory.title[this.$i18n.locale],
        task.title[this.$i18n.locale]
      ]);
      return tasksForJexcel;
    }
  },
  watch: {
    task() {
      this.subtasksCache = {};
    }
  },
  mounted() {
    this.subtasksCache = {};
    this.generateJexcel();
  },
  methods: {
    getSubtask(task, year, month) {
      const key = `${task.id}_${task.groupIndex}_${year}_${month}`;

      if (this.subtasksCache[key] === undefined) {
        const targetSubtasks = task.subtasks.find(
          ({ start_date, due_date }) => {
            const firstDateOfStartDate = moment(start_date)
              .startOf("month")
              .format("YYYY/MM/DD");
            const lastDateOrDueDate = moment(due_date)
              .endOf("month")
              .format("YYYY/MM/DD");
            const targetDate = moment(`${year}/${month}/2`);
            return moment(targetDate).isBetween(
              firstDateOfStartDate,
              lastDateOrDueDate
            );
          }
        );
        this.subtasksCache[key] = targetSubtasks;
      }
      return this.subtasksCache[key];
    },
    isStartSubtaskMonth(task, year, month) {
      const startDate = moment(task.start_date).format("YYYY/M/DD");
      return moment(startDate).isSame(moment(`${year}/${month}`), "month");
    },
    getSubtaskMonthLength(task) {
      let startDate = moment(task.start_date).format("YYYY/M/DD");
      const dueDate = moment(task.due_date).format("YYYY/M/DD");
      let monthCount = 1;
      while (!moment(startDate).isSame(dueDate, "month")) {
        monthCount += 1;
        const workStartDate = moment(startDate).format("YYYY/M/DD");
        startDate = moment(workStartDate).add(1, "M");
      }
      return monthCount;
    },
    isFirstNewCategory(taskId, taskGroupIndex) {
      const currentIndex = this.tasks.findIndex(
        ({ id, groupIndex }) => id === taskId && groupIndex === taskGroupIndex
      );
      if (currentIndex === 0) return true;

      const currentCategoryId = this.tasks[currentIndex].taskCategory.id;
      const previousCategoryId = this.tasks[currentIndex - 1].taskCategory.id;
      return currentCategoryId !== previousCategoryId;
    },
    getRepeatCategoryCount(categoryId) {
      return this.tasks.filter(({ taskCategory: { id } }) => categoryId === id)
        .length;
    },
    getRepeatTaskCount(taskId) {
      return this.tasks.filter(({ id }) => taskId === id).length;
    },
    generateJexcel() {
      this.spreadsheet = jexcel(this.$refs.spreadsheet, {
        stripHTML: false,
        colWidths: [400, 260],
        displayAlerts: false,
        nestedHeaders: [
          [
            {
              title: "",
              colspan: "2"
            },
            ...this.yearColSpanMerges
          ]
        ],
        updateTable: (el, cell) => {
          cell.classList.add("readonly");
        },
        tableWidth: "600px"
      });

      const [
        CATEGORY_COLUMN_NUM,
        CATEGORY_COLUMN_DEFAULT_WIDTH,
        TASK_COLUMN_WIDTH,
        TASK_COLUMN_DEFAULT_WIDTH
      ] = [0, 260, 1, 400];
      this.spreadsheet.setWidth(
        CATEGORY_COLUMN_NUM,
        CATEGORY_COLUMN_DEFAULT_WIDTH
      );
      this.spreadsheet.setWidth(TASK_COLUMN_WIDTH, TASK_COLUMN_DEFAULT_WIDTH);
    }
  }
};
</script>

<style scoped>
.category,
.task {
  font-size: 14px;
  color: #606266;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding-left: 8px;
}
</style>
